    <div class="row mainHeadVault">
        <div class="col-sm-4 noLeftPadding">
            <h3>Checklist Templates</h3>
        </div>
        <div class="col-sm-8 noRightPadding text-right">
            <!-- <button (click)="exportChecklist()" id="refresh-TemplateForm m-1" type="button"
                class="btn btn-primary createBtn createBtnPadding">
                <i class="pi pi-file-excel" style="font-size: 1.3rem"
                    aria-hidden="true"></i>
                &nbsp;
                Export to Excel</button> -->
            <button [hidden]=" this.getStatusChecklist !== 'Created' && this.getStatusChecklist!=='In-Progress'"
                id="create-TemplateForm" (click)="createChecklistTemplate()" authorized [permission]="create_checkList"
                class="createBtn btnRightMargin">
                <img src="../../../assets/layout/images/create_icon.svg" alt="Create Icon" />
                Create Checklist Template
            </button>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 noPadding">
            <div class="styleTable">
                <ob-ng-table #Obtable [settings]="tableSettings" (tableOutput)="loadchecklistTemplates($event)">
                    <div class="Tablefilter checklisttablefilter">
                        <ng-container [ngTemplateOutlet]="checklistGridFilters">
                        </ng-container>
                    </div>
                </ob-ng-table>
            </div>
        </div>
    </div>


<ng-template #checklistGridFilters>
    <div class="row bkgtablefilter">
        <div class="col-md-10 noPadding inputUpload">
            <input id="search" [(ngModel)]="checklistFilters.Filter" (keyup.enter)="applyFilters()" #searchFilter
                type="text" placeholder="Search" type="text">
        </div>
        <!-- <div class="col-3">
        <input id="Name"
          placeholder="Name" [(ngModel)]="checklistFilters.NameFilter"
          (keyup.enter)="applyFilters()" type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
      </div>
      <div class="col-3">
        <input id="Description"
          placeholder="Description" [(ngModel)]="checklistFilters.DescriptionFilter"
          (keyup.enter)="applyFilters()" type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
      </div> -->
        <div class="col-md-2 noRightPadding">
            <button class="createBtn btnRightMargin btnWidthFull" (click)="clearFilter()" type="button"><i
                    class="pi pi-times-circle"></i> Clear</button>
        </div>
    </div>
</ng-template>

<app-delete-popup [deleteDialogVisible]="deleteDialogVisible" (yes)="deleteconfirm()" (No)="hide_delete_popup()"
    (hide)="hide_delete_popup()"></app-delete-popup>