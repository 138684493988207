import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formally-select',
  templateUrl: './formally-select.component.html',
  styleUrls: ['./formally-select.component.scss']
})
export class FormallySelectComponent  extends FieldType<FieldTypeConfig> implements OnInit {

    ddoptions:any;
  constructor() {
    super();

   }

  ngOnInit() {
    this.ddoptions=this.props?.options;
    if (this.props?.options) {
      if((this.props?.options as any[]).length)
      {
        this.formControl.setValue((this.props?.options as any[])[0].value)
      }
    }
  }

}
