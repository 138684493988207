// import { OAuthService } from 'angular-oauth2-oidc';
import { Component, Inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
@Component({
  selector: 'app-log-out.ts',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss']
})
export class LogOutComponent {
  constructor(
    private msalService: MsalService,    
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) { }

  ngOnInit(){

  }

  public ngAfterViewInit(): void {
    const activeAccount =
      this.msalService.instance.getActiveAccount() ||
      this.msalService.instance.getAllAccounts()[0];

    // Extract login hint to use as logout hint
    const logoutHint = localStorage.getItem('id_token');

    this.clearAppData();

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.msalService.instance.initialize().then(() => {
        this.msalService.logoutRedirect({
          account: activeAccount,
          idTokenHint: logoutHint
        });
      })
    }
  }

  private clearAppData(): void {
    sessionStorage.clear();
    localStorage.clear();
  }
}
