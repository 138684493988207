<div class="dock-window">
    <ul>
        <!-- class="activeIcons" -->
        <li *ngIf="hasContributorPermission" 
        [ngClass]="{activeIcons: selectedMenu === 'contributor'}" (click)="SelectRole('contributor')" pTooltip="Contributor" >
        <img src="../../assets/layout/images/Collaborator.svg" /></li>
        <li *ngIf="hasAdminPermission" [ngClass]="{activeIcons: selectedMenu === 'admin'}" (click)="SelectRole('admin')" pTooltip="Administrator" >
        <img src="../../assets/layout/images/Admin.svg" /></li>
        <li *ngIf="hasDeveloperPermission" [ngClass]="{activeIcons: selectedMenu === 'developer'}" (click)="SelectRole('developer')" pTooltip="Developer" >
            <!-- *ngIf="hasDeveloperPermission" -->
        <img src="../../assets/layout/images/Developer.svg" /></li>
    </ul>
</div>
<ul class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>

</ul>
