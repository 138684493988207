<!-- <nav>
  <ng-container *ngFor="let crumb of breadcrumbs; let last = last">
    <ng-container *ngIf="!last">
      <img  src="../../../assets/layout/images/home.svg" alt="icon" style="cursor: pointer;" (click)="navigateToRoute(crumb.route)" class="breadcrumb-icon">
      <a (click)="navigateToRoute(crumb.route)">{{ crumb.label }}</a> &gt; 
    </ng-container>
    <ng-container *ngIf="last">
      {{ crumb.label }}
    </ng-container>
  </ng-container>
</nav> -->


<nav>
  <ng-container *ngFor="let crumb of breadcrumbs; let last = last">
    <ng-container *ngIf="!last">
      <img src="../../../assets/layout/images/home.svg" alt="icon" style="cursor: pointer;"
        (click)="navigateToRoute(crumb.route)" class="breadcrumb-icon">
      <a class="link" (click)="navigateToRoute(crumb.route)">{{ crumb.label }} </a> <span class="breadArrow">&gt; </span>
    </ng-container>
    <!-- <ng-container *ngIf="last"> -->
    <ng-container *ngIf="crumb.subChild">
      <a class="link" (click)="navigateToRoute(crumb.subChild.route)">  {{ crumb.subChild.label }}</a> <span class="breadArrow">&gt; </span>
    </ng-container>
    <ng-container *ngIf="crumb.anotherSubChild">
      {{ crumb.anotherSubChild.label}} <span class="breadArrow">&gt; </span>
    </ng-container>
    <ob-ng-tooltip [data]="crumb.label" [size]="120"></ob-ng-tooltip>
    <!-- {{ crumb.label }} -->
    <!-- </ng-container> -->
  </ng-container>
</nav>