<!-- <div class="dock-window">
    <ul>
        <li *ngIf="hasContributorPermission" 
        [ngClass]="{activeIcons: selectedMenu === 'contributor'}" (click)="SelectRole('contributor')" pTooltip="Contributor" >
        <img src="../../assets/layout/images/Collaborator.svg" /></li>
        <li *ngIf="hasAdminPermission" [ngClass]="{activeIcons: selectedMenu === 'admin'}" (click)="SelectRole('admin')" pTooltip="Administrator" >
        <img src="../../assets/layout/images/Admin.svg" /></li>
        <li *ngIf="hasDeveloperPermission" [ngClass]="{activeIcons: selectedMenu === 'developer'}" (click)="SelectRole('developer')" pTooltip="Developer" >

        <img src="../../assets/layout/images/Developer.svg" /></li>
        <li *ngIf="showDocumentTaskList" 
        [ngClass]="{activeIcons: selectedMenu === 'Documents'}" (click)="SelectRole('Documents')" pTooltip="Documents" >
        <img src="../../assets/layout/images/Collaborator.svg" /></li>
        
    </ul>
</div>
<ul class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>

</ul> -->

<div class="dock-window">
    <ul>
        <li *ngIf="hasContributorPermission" 
            [ngClass]="{activeIcons: selectedMenu === 'contributor'}" 
            (click)="SelectRole('contributor')" 
            pTooltip="Contributor">
            <img src="../../assets/layout/images/Collaborator.svg" />
        </li>
        <li *ngIf="hasAdminPermission" 
            [ngClass]="{activeIcons: selectedMenu === 'admin'}" 
            (click)="SelectRole('admin')" 
            pTooltip="Administrator">
            <img src="../../assets/layout/images/Admin.svg" />
        </li>
        <li *ngIf="hasDeveloperPermission" 
            [ngClass]="{activeIcons: selectedMenu === 'developer'}" 
            (click)="SelectRole('developer')" 
            pTooltip="Developer">
            <img src="../../assets/layout/images/Developer.svg" />
        </li>
        <li *ngIf="showDocumentTaskList" 
            [ngClass]="{activeIcons: selectedMenu === 'Documents'}" 
            (click)="SelectRole('Documents')" 
            pTooltip="Documents">
            <img src="../../assets/layout/images/Collaborator.svg" />
        </li>
        
       
    </ul>
</div>

<ul class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
     <li *ngIf="selectedMenu === 'Documents'">
        <button 
        (click)="loadMoreDocuments()" 
        [disabled]="(DocumentsList.length + skipCount) == documentTotalCount" 
        style="display: inline-block; padding: 10px 20px; background-color: #007bff; color: white; border: none; border-radius: 5px; text-decoration: none; cursor: pointer; font-size: 14px; transition: background-color 0.3s, transform 0.3s; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);"
        onmouseover="this.style.backgroundColor='#0056b3'; this.style.transform='scale(1.05)';" 
        onmouseout="this.style.backgroundColor='#007bff'; this.style.transform='scale(1)';"  
        [ngStyle]="{'background-color': (DocumentsList.length + skipCount) == documentTotalCount ? '#cccccc' : '#007bff', 'cursor': (DocumentsList.length + skipCount) == documentTotalCount ? 'not-allowed' : 'pointer', 'color': (DocumentsList.length + skipCount) == documentTotalCount ? '#666666' : 'white'}"
    >
        Load More {{DocumentsList.length}}
    </button>
    
    </li>
</ul>
