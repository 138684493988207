import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadCrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {

  breadcrumbs: Breadcrumb[] = [];

  constructor(private breadcrumbService: BreadCrumbService,  private router: Router) {}
  
  ngOnInit() {
    this.breadcrumbService.breadcrumbs$.subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
      console.log(this.breadcrumbs,'breadCrumb')
    });
  }

  navigateToRoute(route: string) {
    this.router.navigateByUrl(route);
  }
}
// export interface Breadcrumb {
//   label: string;
//   route: string;
// }
export interface Breadcrumb {
  label: string;
  route: string;
  subChild?: {
    label: string;
    route: string;
  };
  anotherSubChild?: {
    label: string;
    route: string;
};
}